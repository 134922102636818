application.register("comp_references_single_aldrov", class extends Stimulus.Controller {
    connect() {
        const self = this;

        this._cardsContactless = self.targets.findAll('cardContactless')

        const stepBreakpoints = []
        this._cardsContactless.forEach((step, index) => {
            stepBreakpoints.push(100 / this._cardsContactless.length * index)
        })
        let currentBreakpoint = 0

        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            '(min-width: 60em)': () => {
                const timelineContactless = new ScrollTrigger({
                    trigger: self.targets.find('sceneContactless'),
                    start: 'top-=70% top',
                    end: `+${100 * self._cardsContactless.length}%`,
                    scrub: true,
                    pin: true,
                    markers: false,
                    onUpdate: self => {
                        const currentProgress = self.progress

                        stepBreakpoints.forEach((breakpoint, index) => {
                            if (currentProgress >= breakpoint / 100 && currentProgress < (breakpoint + 5) / 100) {
                                if (breakpoint !== currentBreakpoint) {
                                    currentBreakpoint = breakpoint

                                    this._cardsContactless.forEach(item => {
                                        item.classList.remove('active')
                                    })
                                    this._cardsContactless[index].classList.add('active')
                                }
                            }
                        })
                    }
                })
            }
        })
    }
})
