application.register("layout_aside", class extends Stimulus.Controller {
    connect() {
        let selector = this.element;
        const self = this

        self._instance = this;

        bodyLoaded(function () {
            cssLoaded(function () {
                self._elms = selector.querySelectorAll(`[class^="comp_"].background--color-main`);
                self._anchors = selector.querySelectorAll(`[class^="comp_"][data-lib-anchor-id]`);
                self._nav = self.targets.find("nav");
                self._sections = [];
                self._anchorSections = {}
                if (self._elms[0] !== null && typeof self._nav !== "undefined") {
                    let layout_offset = selector.offsetTop;

                    Array.from(self._anchors).forEach((section)=>{
                        let offset = (typeof section.dataset.libAnchorOffset !== "undefined") ? parseInt(section.dataset.libAnchorOffset) : 0;

                        self._anchorSections[section.getAttribute("data-lib-anchor-id")] = section.offsetTop + layout_offset - offset;
                    });
                }
            });
        })
    }

    scrollspy() {
        this._elms.forEach((elm, index) => {
            let top = elm.getBoundingClientRect().top - this._nav.clientHeight + document.body.scrollTop;
            let bottom = -elm.clientHeight;

            if (document.querySelector("#layout_aside > .col--right").lastChild["classList"].contains("background--color-main") && this._elms.length - 1 === index) {
                bottom = bottom - document.documentElement.clientHeight;
            }

            this._sections[index] = top < 0 && top > bottom;

            if (this._sections.includes(true)) {
                this._nav.classList.add("text--color-invert");
            } else {
                let topFirst = this._elms[0].getBoundingClientRect().top - this._nav.clientHeight + document.body.scrollTop;

                if (!this._elms[0].previousSibling && this._elms[0].classList.contains("background--color-main") && topFirst > 0) {
                    return false;
                }

                this._nav.classList.remove("text--color-invert");
            }
        });

        for(let i in this._anchorSections) {
            if(this._anchorSections[i] <= window.scrollY) {
                let active = this._nav.querySelector(".state--active")
                if(active !== null) {
                    active.classList.remove("state--active");
                }
                this._nav.querySelector(`[href="#${i}"]`).classList.add("state--active");
            }
        }
    }
});
